// Bootstrap overrides
//
// Color system
//

$white: #fff !default;
/*
$gray-100:                #f0f3f5 !default;
$gray-200:                #c2cfd6 !default;
$gray-300:                #a4b7c1 !default;
$gray-400:                #869fac !default;
$gray-500:                #678898 !default;
$gray-600:                #536c79 !default;
$gray-700:                #3e515b !default;
$gray-800:                #29363d !default;
$gray-900:                #151b1e !default;
*/
$black: #000 !default;


$gray-100: #ebeaea;
$gray-200: #c3c1c0;
$gray-300: #9c9796;
$gray-400: #746d6c;
$gray-500: #4c4442;
$gray-600: #382F2D;
$gray-700: #27211f;
$gray-800: #161312;
$gray-900: #060504;


$blue: #20a8d8 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #f86c6b !default;
$orange: #f8cb00 !default;
$yellow: #ffc107 !default;
$green: #4dbd74 !default;
$teal: #20c997 !default;
$cyan: #63c2de !default;

$slowVitaGreen: #382F2D !default;
$slowVitaYellow: #9c9796 !default;
$input-placeholder-color: $gray-500 !default;

$colors: (
        blue: $blue,
        indigo: $indigo,
        purple: $purple,
        pink: $pink,
        red: $red,
        orange: $orange,
        yellow: $yellow,
        green: $green,
        teal: $teal,
        cyan: $cyan,
        white: $white,
        gray: $gray-600,
        gray-dark: $gray-800
);

$theme-colors: (
        primary: $slowVitaGreen,
        secondary: $slowVitaYellow,
        success: $green,
        info: $cyan,
        warning: $yellow,
        danger: $red,
        light: $gray-100,
        dark: $gray-800
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions: true;
$enable-rounded: false;

// Body
//
// Settings for the `<body>` element.

$body-bg: $white;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base: 0.875rem;

// Breadcrumbs

$breadcrumb-bg: #fff;
$breadcrumb-margin-bottom: 1.5rem;

// Cards

$card-border-color: $gray-200;
$card-cap-bg: $gray-100;

// Dropdowns

$dropdown-padding-y: 0;
$dropdown-border-color: $gray-200;
$dropdown-divider-bg: $gray-100;

// Buttons

$btn-secondary-border: $gray-300;

// Progress bars

$progress-bg: $gray-100;

// Tables

$table-bg-accent: $gray-100;
$table-bg-hover: $gray-100;

// Forms

$input-group-addon-bg: $gray-100;
$input-border-color: $gray-200;
$input-group-addon-border-color: $gray-200;
